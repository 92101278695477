/* Colors */
$primary-color: #c5bda0 !default;
$primary-color-new: #bd9a5f !default;
$primary-color-dark: #8c826e !default;
$primary-color-dark-new: #a18757 !default;
$primary-color-dark-2: #857450 !default;
$primary-color-light: #e8e5da !default;
$secondary-color: #ffffff !default;
$secondary-color-dark: #f4f3f0 !default;
$secondary-color-dark-2: #e2dfd7 !default;
$secondary-color-dark-3: #d9dddf !default;
$secondary-color-dark-4: #b4bbc0 !default;

$text-white: $secondary-color !default;
$text-disabled: #324757;
$button-blue: #1f4067 !default;
$professional-color: #4a8bff !default;
$border-color: #c5cdd4 !default;
$insight-fund-card-border-color: #c5cdd4 !default;

/* Color of disabled button in Chrome */
$button-disabled-background-color: rgba(#efefef, 0.3);

$error-color: #ff4910;

/* Font family */
$primary-font-family: 'Lato', sans-serif;
$secondary-font-family: 'Barlow', sans-serif;
$tertiary-font-family: 'Roboto', sans-serif;
$register-page-font-family: 'Barlow', sans-serif;

/* Box */
$primary-box-shadow: 0 0 8px 0 rgba(5, 28, 45, 0.1);
$primary-box-border: 1px solid rgba(5, 28, 45, 0.1);

/* Numeric */
$content-width: 1024px;
$content-max-width: 1343px;
$alert-banner-height: 120px;
$alert-banner-responsive-height: 180px;
$header-height: 100px;
$header-responsive-height: 80px;
$portal-header-height: 71px;
$portal-sidebar-width: 70px;
$portal-sidebar-expand-width: 270px;

$title-size: 36px;
$content-size: 18px;

$header-resp-height: 80px;
$header-resp-margin-top: 10px;
$title-resp-size: 19px;

/*
 * Phone: 360px - 768px
 * Tablet: 768px - 1024px
 * Normal screen: 1024px - 2500px
 * Ultra wide screen: >2500px
 *
 * Breakpoint suggestions:
 *   Phone: < md / < sm
 *   Table: < lg
 *   Ultra wide screen: > xxl
 */
$breakpoints: (
    xxs: 360px,
    xs: 480px,
    sm: 576px,
    md: 768px,
    phone: 768px,
    lg: 1024px,
    tablet: 1024px,
    xl: 1440px,
    small-screen: 1440px,
    xxl: 2500px,
);

/* stylelint-disable */
:export {
    primaryColorLight: $primary-color-light;
    secondaryColorDark: $secondary-color-dark;
    breakpointsXxs: map-get($breakpoints, xxs);
    breakpointsXs: map-get($breakpoints, xs);
    breakpointsSm: map-get($breakpoints, sm);
    breakpointsMd: map-get($breakpoints, md);
    breakpointsLg: map-get($breakpoints, lg);
    breakpointsXl: map-get($breakpoints, xl);
    breakpointsXxl: map-get($breakpoints, xxl);
    headerHeight: $header-height;
    headerResponsiveHeight: $header-responsive-height;
}
/* stylelint-enable */
