@import '/app/src/shared/theme/mixins.scss'; @import '/app/src/shared/theme/var.scss'; @import '/app/src/theme/var.scss';
.container {
    .section {
        text-align: center;

        .title {
            margin-bottom: 0;
            font-weight: bold;
            font-size: 100px;
        }

        .content {
            margin-bottom: 50px;
        }
    }
}
