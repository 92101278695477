// Respond below.
@mixin respond-below($breakpoint) {
    // If the breakpoint exists in the map.
    @if map-has-key($breakpoints, $breakpoint) {
        // Get the breakpoint value.
        $breakpoint-value: map-get($breakpoints, $breakpoint);

        // Write the media query.
        @media (max-width: $breakpoint-value) {
            @content;
        }

        // If the breakpoint doesn't exist in the map.
    } @else {
        // Log a warning.
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}

@mixin respond-below-multiple($bps) {
    @each $bp in $bps {
        @include respond-below($bp) {
            @content;
        }
    }
}

@mixin respond-between($lower, $upper) {
    // If both the lower and upper breakpoints exist in the map.
    @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
        // Get the lower and upper breakpoints.
        $lower-breakpoint: map-get($breakpoints, $lower);
        $upper-breakpoint: map-get($breakpoints, $upper);

        // Write the media query.
        @media (min-width: $lower-breakpoint + 1px) and (max-width: ($upper-breakpoint)) {
            @content;
        }

        // If one or both of the breakpoints don't exist.
    } @else {
        // If lower breakpoint is invalid.
        @if (map-has-key($breakpoints, $lower) ==false) {
            // Log a warning.
            @warn 'Your lower breakpoint was invalid: #{$lower}.';
        }

        // If upper breakpoint is invalid.
        @if (map-has-key($breakpoints, $upper) ==false) {
            // Log a warning.
            @warn 'Your upper breakpoint was invalid: #{$upper}.';
        }
    }
}

@mixin respond-above($breakpoint) {
    // If the breakpoint exists in the map.
    @if map-has-key($breakpoints, $breakpoint) {
        // Get the breakpoint value.
        $breakpoint-value: map-get($breakpoints, $breakpoint);

        // Write the media query.
        @media (min-width: $breakpoint-value + 1px) {
            @content;
        }

        // If the breakpoint doesn't exist in the map.
    } @else {
        // Log a warning.
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}

// TODO Optimize and update when breakpoints and design are fixed and updated
@mixin respond-below-with-fixed-interval($property, $size-for-xxl, $interval) {
    @include respond-below(xxl) {
        #{$property}: $size-for-xxl;
    }

    @include respond-below(xl) {
        #{$property}: $size-for-xxl - $interval;
    }

    // Temp handling for xl and lg interval
    @include respond-below(lg) {
        #{$property}: $size-for-xxl - $interval * 3;
    }

    @include respond-below(md) {
        #{$property}: $size-for-xxl - $interval * 4;
    }

    @include respond-below(xs) {
        #{$property}: $size-for-xxl - $interval * 5;
    }

    @include respond-below(xxs) {
        #{$property}: $size-for-xxl - $interval * 6;
    }
}

@mixin transform($transforms) {
    -webkit-transform: $transforms;
    -moz-transform: $transforms;
    -ms-transform: $transforms;
    transform: $transforms;
}

@mixin transition($transition...) {
    -moz-transition: $transition;
    -o-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
}

@mixin transition-property($property...) {
    -moz-transition-property: $property;
    -o-transition-property: $property;
    -webkit-transition-property: $property;
    transition-property: $property;
}

@mixin transition-duration($duration...) {
    -moz-transition-property: $duration;
    -o-transition-property: $duration;
    -webkit-transition-property: $duration;
    transition-property: $duration;
}

@mixin transition-timing-function($timing...) {
    -moz-transition-timing-function: $timing;
    -o-transition-timing-function: $timing;
    -webkit-transition-timing-function: $timing;
    transition-timing-function: $timing;
}

@mixin transition-delay($delay...) {
    -moz-transition-delay: $delay;
    -o-transition-delay: $delay;
    -webkit-transition-delay: $delay;
    transition-delay: $delay;
}

@mixin user-select($select) {
    -moz-user-select: -moz-#{$select};
    -khtml-user-select: $select;
    -webkit-user-select: $select;
    -o-user-select: $select;
    user-select: $select;
}

@mixin fixed-width-container($width: $content-width) {
    width: 100%;
    max-width: $width;
    margin: 0 auto;
    padding: 0 12px;
}

@mixin respond-font-size($desktop, $mobile: false) {
    font-size: $desktop;

    // If the breakpoint exists in the map.
    @include respond-below(lg) {
        @if $mobile==false {
            $desktop: $desktop * 0.81;
        }

        font-size: $mobile;
    }
}

@mixin titleText($color: $secondary-color, $resp-size: $title-resp-size) {
    color: $color;
    font-size: $title-size;
    font-family: $secondary-font-family;
    line-height: 1.2;

    @include respond-below(lg) {
        font-size: $resp-size;
    }

    @content;
}

@mixin title($color: $secondary-color, $resp-size: $title-resp-size) {
    .title {
        @include titleText($color, $resp-size);

        @content;
    }
}

@mixin contentText($color: $secondary-color) {
    color: $color;
    font-weight: 300;
    font-size: $content-size;
    font-family: $primary-font-family;
    line-height: 1.5;

    @include respond-below(lg) {
        font-size: 1rem;
    }

    @content;
}

@mixin content($color: $secondary-color) {
    .content {
        @include contentText($color);

        @content;
    }
}

@mixin portalTitle() {
    .portalTitle {
        font-weight: 400;
        font-size: 14px;
        @content;
    }
}

@mixin sectionTitle() {
    color: $theme-color-light;
    font-size: 18px;
    font-family: Lato, sans-serif;
    @content;
}

@mixin disableSelection {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

@mixin line-clamp($max-lines: 2) {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    word-break: break-word;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $max-lines;
}

@mixin big-button($width: 300px, $height: 64px, $font-size: 18px, $smaller-breakpoint: lg) {
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: $width;
        height: $height;
        font-size: $font-size;
        border-radius: 35px / 50%;

        @include respond-below($smaller-breakpoint) {
            @if $width != fit-content {
                width: $width * 0.815;
            }

            height: 48px;
            font-size: 16px;
            border-radius: 26px / 50%;
        }

        @content;
    }
}

@mixin box-shadow {
    box-shadow: 0 2px 12px 0 rgba(#000000, 0.4);
}

@mixin marketingSectionPadding($desktop: 48px 0, $tablet: 48px 0, $phone: 24px 0) {
    padding: $desktop;

    @include respond-below(tablet) {
        padding: $tablet;
    }

    @include respond-below(phone) {
        padding: $phone;
    }

    @content;
}

@mixin handleHeader {
    width: 100%;
    height: 100%;
    margin-top: $header-height;

    @include respond-below(tablet) {
        margin-top: $header-responsive-height;
    }
}

@mixin fixed($prop, $value) {
    #{$prop}: $value;
}

@mixin f12($prop) {
    @include fixed($prop, 12px);
}

@mixin scale($prop, $base, $plus1, $plus2) {
    #{$prop}: calc(#{$base} + #{$plus1} + #{$plus2});

    @include respond-below(tablet) {
        #{$prop}: calc(#{$base} + #{$plus1});
    }

    @include respond-below(phone) {
        #{$prop}: $base;
    }
}

@mixin r0($prop) {
    @include scale($prop, 0, 4px, 8px);
}

@mixin r1($prop) {
    @include scale($prop, 4px, 4px, 8px);
}

@mixin r2($prop) {
    @include scale($prop, 8px, 4px, 8px);
}

@mixin r3($prop) {
    @include scale($prop, 12px, 4px, 8px);
}

@mixin r4($prop) {
    @include scale($prop, 16px, 4px, 8px);
}

@mixin r5($prop) {
    @include scale($prop, 20px, 4px, 8px);
}

@mixin r6($prop) {
    @include scale($prop, 24px, 4px, 8px);
}

@mixin r7($prop) {
    @include scale($prop, 28px, 4px, 8px);
}

@mixin r8($prop) {
    @include scale($prop, 32px, 8px, 8px);
}

@mixin r9($prop) {
    @include scale($prop, 32px, 8px, 16px);
}

@mixin r10($prop) {
    @include scale($prop, 36px, 8px, 16px);
}

@mixin r11($prop) {
    @include scale($prop, 40px, 8px, 16px);
}

@mixin r12($prop) {
    @include scale($prop, 44px, 8px, 16px);
}

@mixin r13($prop) {
    @include scale($prop, 48px, 8px, 16px);
}

@mixin r14($prop) {
    @include scale($prop, 52px, 8px, 16px);
}

@mixin r15($prop) {
    @include scale($prop, 56px, 8px, 16px);
}

@mixin r16($prop) {
    @include scale($prop, 60px, 8px, 16px);
}

@mixin r17($prop) {
    @include scale($prop, 64px, 8px, 16px);
}

@mixin r18($prop) {
    @include scale($prop, 68px, 8px, 16px);
}

@mixin r19($prop) {
    @include scale($prop, 72px, 8px, 16px);
}

@mixin r20($prop) {
    @include scale($prop, 76px, 8px, 16px);
}

@mixin t1() {
    @include scale(font-size, 13px, 0, 0);
}

@mixin t2() {
    @include scale(font-size, 15px, 0, 0);
}

@mixin t3() {
    @include scale(font-size, 16px, 1px, 0);

    @include respond-above(phone) {
        line-height: 22px;
    }
}

@mixin t4() {
    @include scale(font-size, 16px, 1px, 3px);

    @include respond-above(phone) {
        line-height: 22px;
    }

    @include respond-above(tablet) {
        line-height: 26px;
    }
}

@mixin t5() {
    @include scale(font-size, 17px, 1px, 2px);
}

@mixin t6() {
    @include scale(font-size, 18px, 2px, 4px);

    @include respond-between(phone, tablet) {
        line-height: 26px;
    }
}

@mixin t7() {
    @include scale(font-size, 20px, 4px, 4px);
}

@mixin t8() {
    @include scale(font-size, 24px, 4px, 4px);
}

@mixin t9() {
    @include scale(font-size, 28px, 4px, 8px);

    @include respond-above(phone) {
        line-height: 40px;
    }

    @include respond-above(tablet) {
        line-height: 48px;
    }
}

@mixin t10() {
    @include scale(font-size, 28px, 8px, 12px);
}

@mixin t11() {
    @include scale(font-size, 28px, 24px, 8px);

    @include respond-above(phone) {
        line-height: 64px;
    }

    @include respond-above(tablet) {
        line-height: 80px;
    }
}

@mixin t12() {
    @include scale(font-size, 32px, 28px, 12px);

    @include respond-between(phone, tablet) {
        line-height: 80px;
    }
}

@mixin handle900 {
    @media (max-width: 900px) {
        @content;
    }
}

@mixin handle800 {
    @media (max-width: 1000px) {
        @content;
    }
}

@mixin handle1200 {
    @media (max-width: 1200px) {
        @content;
    }
}
